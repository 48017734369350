module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-180559271-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Techvizon","short_name":"Techvizon","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","prefer_related_applications":true,"icon":"src/images/maskable_icon.png","icons":[{"src":"/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/icon-192x192.png","sizes":"196x196","type":"image/png","purpose":"maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"eb76b9013df264f32eaf6fcf816f8cbd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
